<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 15:51:39
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

import CheckProduct from './components/check_product.vue';
import CheckCustomer from './components/check_customer.vue';
import SettleProduct from './components/settle_product.vue';
import SettleGoods from './components/settle_goods.vue';

formCreate.component('CheckProduct', CheckProduct);
formCreate.component('CheckCustomer', CheckCustomer);
formCreate.component('SettleProduct', SettleProduct);
formCreate.component('SettleGoods', SettleGoods);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],
      buttons: {
        submitAudit: true,
      },
      buttonText: {
        submitAudit: '暂存',
      },
      path: {
        order: {
          cal: '/dms/m/order/cal',
          add: '/dms/m/order/add',
        },
        goods: {
          cal: '/dms/m/order/cal/rebate',
          add: '/dms/m/order/add/rebate',
        },
        free: {
          cal: '/dms/m/order/cal/free',
          add: '/dms/m/order/add/free',
        },
      },
    };
  },

  async mounted() {
    if (this.formConfig.orderType !== '1') {
      this.buttons.submitAudit = false;
    }
    if (this.formConfig.code === 'view') {
      this.buttons.submitAudit = false;
    }
    this.rule = await this.getBeforFormRule('dms_order_manage_form');
    this.rule.map((v) => {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
        disabled: this.disabledField(rowData),
      };
      if (rowData.field === 'cusName') {
        rowData.on = {
          ...rowData.on,
          setcustomerName: this.setcustomerName,
        };
      }
      if (rowData.field === 'items') {
        // rowData.type = 'SettleGoods';
        rowData.props = {
          ...rowData.props,
          customerCode: '',
          // customerCode: 'FLPOC2020001',
          errMsg: '',
          errType: false,
        };
        rowData.on = {
          ...rowData.on,
          quantityOrder: this.quantityOrder,
        };
        rowData.value = {
          tableData: [],
          backList: [],
          giftList: [],
          normalList: [],
        };
      }
      if (rowData.field === 'temporaryFlag') {
        rowData.value = 'N';
      }
      if (rowData.field === 'pickAdress') {
        rowData.restful = '/mdm/mdmCustomerAddressController/listCondition';
        rowData.headers = { functionCode: 'select_pick_address' };
        rowData.refresh = true;
        rowData.restfulParams = {
          customerCode: '',
        };
        rowData.optionsKey = {
          value: 'detailedAddress',
          label: 'detailedAddress',
        };
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            const data = rowData.options.find((a) => a.detailedAddress === val);
            this.setValue({
              contactsName: data.contactName,
              contactsPhone: data.contactPhone,
            });
          },
        };
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'discountAmount') {
        rowData.on = {
          ...rowData.on,
          change: () => this.quantityOrder(),
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    this.hiddenFields(true, ['type']);
    if (this.formConfig.orderType) {
      this.setValue({
        type: this.formConfig.orderType,
      });
      this.getRule('items').props.orderType = this.formConfig.orderType;
    }
    if (this.formConfig.id) {
      this.getData();
    }
  },

  methods: {
    setcustomerName(val) {
      this.setValue({
        cusName: val.customerName,
        cusCode: val.customerCode,
      });
      this.searchCustomerAmount();
      this.getRule('items').props.customerCode = val.customerCode;
      this.getRule('pickAdress').props.disabled = false;
      this.getRule('pickAdress').restfulParams = {
        customerCode: val.customerCode,
      };
      this.setValue({
        items: { tableData: [] },
      });
    },
    // 查询客户余额
    searchCustomerAmount() {
      const params = {
        customerCode: this.getFieldValue('cusCode'),
      };
      request
        .post(
          '/fee/feePoolController/queryPoolAmount',
          params,
        )
        .then((res) => {
          if (res.success) {
            this.setValue({
              usableAmount: res.result.discountUsableAmount,
            });
          }
        });
    },
    disabledField(row) {
      let isDisabled = false;
      switch (row.field) {
        case 'contactsName':
          isDisabled = true;
          break;
        case 'contactsPhone':
          isDisabled = true;
          break;
        case 'amount':
          isDisabled = true;
          break;
        case 'promotionAmount':
          isDisabled = true;
          break;
        case 'repAmount':
          isDisabled = true;
          break;
        case 'usableAmount':
          isDisabled = true;
          break;
        case 'maxDiscountAmount':
          isDisabled = true;
          break;
        case 'discountAmount':
          isDisabled = false;
          break;
        case 'actualAmount':
          isDisabled = true;
          break;
        case 'cusCode':
          isDisabled = true;
          break;
        default:
          break;
      }
      return isDisabled;
    },
    // 解析返回的列表数据
    handleTableData(groupItemVos) {
      const tableData = [];
      groupItemVos.forEach((v) => {
        if (v.giftList) {
          v.giftList.map((a) => {
            const rowData = a;
            rowData.hideControl = true;
            rowData.lineType = '20';
            if (v.normalList) {
              rowData.parentProductCode = v.normalList[0].productCode;
            }

            // rowData.promotionPolicyName = rowData.feeName;
            // rowData.promotionPolicyCode = rowData.feeCode;
            return rowData;
          });
        }
        if (v.normalList) {
          v.normalList.forEach((a) => {
            const {
              promotionPolicyId,
              promotionType,
              promotionResultDate,
              promotionResultType,
              lineNo,
            } = a;
            const aData = a;
            if (promotionType !== '0') {
              aData.resultDataType = promotionResultType;
              aData.resultData = promotionResultDate;
            } else {
              // console.log(resultData);
              const giftList = [];
              if (a.giftList) {
                a.giftList.map((b) => {
                  const bData = b;
                  bData.hideControl = true;
                  bData.lineType = '20';
                  bData.promotionPolicyName = aData.promotionPolicyInfo;
                  bData.promotionPolicyCode = aData.promotionPolicyCode;
                  bData.parentProductCode = aData.productCode;
                  bData.productNum = promotionResultType === 'number'
                    ? promotionResultDate
                    : '0';
                  bData.amount = promotionResultType === 'amount'
                    ? promotionResultDate
                    : '-';
                  bData.parentProductCode = aData.productCode;
                  return bData;
                });
              }

              if (promotionResultType === 'amount') {
                if (a.giftList) {
                  a.giftList.map((c) => {
                    const cData = c;
                    cData.amount = promotionResultType === 'amount'
                      ? promotionResultDate
                      : '-';
                    cData.parentProductCode = aData.productCode;
                    cData.productNum = Math.floor(cData.amount / cData.price);
                    return cData;
                  });
                }
              }
            }
            tableData.push({
              ...aData,
              giftList: v.giftList || [],
              lineType: '10',
              // promotionPolicyName: a.feeName,
              // promotionPolicyCode: a.feeCode,
            });
          });
        }
        if (v.backList) {
          v.backList.forEach((a) => {
            tableData.push({
              ...a,
              hideControl: true,
              lineType: '30',
            });
          });
        }
      });
      // console.log(tableData);
      return tableData;
    },
    getData() {
      let path = '/dms/m/order/findDetailsById/';
      if (this.formConfig.code === 'view') {
        path = '/dms/m/order/findSimpleDetailsById/';
      }
      request
        .get(path + this.formConfig.id)
        .then((res) => {
          if (res.success) {
            const { groupItemVos } = res.result;
            const tableData = this.handleTableData(groupItemVos);
            this.formConfig.orderType = `${res.result.type}`;
            if (this.formConfig.orderType !== '1') {
              this.buttons.submitAudit = false;
            } else {
              this.buttons.submitAudit = true;
            }

            this.getRule('items').props.customerCode = res.result.cusCode;
            this.getRule('items').props.disabled = this.formConfig.code === 'view';
            this.getRule('items').props.orderType = `${res.result.type}`;
            this.getRule('pickAdress').props.disabled = this.formConfig.code === 'view';
            this.getRule('pickAdress').restfulParams = {
              customerCode: res.result.cusCode,
            };
            this.setValue({
              ...res.result,
              items: {
                tableData,
              },
            });
            this.searchCustomerAmount();
          }
        });
    },
    // 计算价格
    quantityOrder() {
      // if (!this.getFormData()) return false;
      const items = [];
      this.getFieldValue('items').tableData.forEach((v) => {
        const rowData = v;
        const normalList = [];
        const giftList = [];
        const backList = [];
        if (rowData.lineType === '10') {
          rowData.promotionPolicyName = rowData.promotionPolicyInfo || rowData.promotionPolicyName;
          delete rowData.options;
          delete rowData.promotionPolicyInfo;
          // eslint-disable-next-line radix
          rowData.productNum = parseInt(rowData.productNum);
          normalList.push({
            promotionPolicyCode: rowData.promotionPolicyCode,
            promotionPolicyName: rowData.promotionPolicyName,
            promotionPolicyId: rowData.promotionPolicyId,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        } else if (rowData.lineType === '30') {
          backList.push({
            feeCode: rowData.feeCode,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        } else {
          giftList.push({
            promotionPolicyCode: rowData.promotionPolicyCode,
            promotionPolicyName: rowData.promotionPolicyName,
            promotionPolicyId: rowData.promotionPolicyId,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        }
        if (rowData.giftList && rowData.giftList.length > 0) {
          rowData.giftList.forEach((a) => {
            giftList.push({
              promotionPolicyCode: rowData.promotionPolicyCode,
              promotionPolicyName: rowData.promotionPolicyName,
              promotionPolicyId: rowData.promotionPolicyId,
              productCode: a.productCode,
              productName: a.productName,
              productNum: a.productNum,
              imgUrl: a.imgUrl,
              saleUnitName: a.saleUnitName,
            });
          });
          // giftList = giftList.concat(rowData.giftList);
        }
        delete rowData.giftList;
        items.push({
          backList,
          giftList,
          normalList,
        });
      });
      const params = {
        // ...this.getFormData(),
        discountAmount: this.getFieldValue('discountAmount'),
        cusCode: this.getFieldValue('cusCode'),
        fromType: 4,
        groupItemVos: items,
      };
      delete params.items;
      let path = '';
      switch (this.formConfig.orderType) {
        case '1':
          path = this.path.order.cal;
          break;
        case '6':
          path = this.path.goods.cal;
          break;
        case '7':
          path = this.path.free.cal;
          break;
        default:
          break;
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          if (res.result.calSucessFlag === 'N') {
            if (res.result.calSucessMsgType === '0') {
              this.$message.error(res.result.calSucessMsg);
            }
          }
          const tableData = [];
          this.handleTableData(res.result.groupItemVos).forEach((v, k) => {
            const rowData = v;
            tableData.push({
              ...this.getFieldValue('items').tableData[k],
              giftList: rowData.giftList,
              lineNo: rowData.lineNo,
              calSucessMsg: rowData.calSucessMsg,
              isErr: rowData.calSucessFlag === 'N',
            });
            // return rowData;
          });
          this.getRule('discountAmount').props.max = res.result.maxDiscountAmount;
          this.setValue({
            amount: res.result.amount,
            promotionAmount: res.result.promotionAmount,
            repAmount: res.result.repAmount,
            actualAmount: res.result.actualAmount,
            discountAmount: res.result.discountAmount,
            maxDiscountAmount: res.result.maxDiscountAmount,
            items: {
              tableData,
            },
          });
        }
      });
    },
    // 提交表单
    submit(btn) {
      if (!this.getFormData()) return false;
      const items = [];
      this.getFieldValue('items').tableData.forEach((v) => {
        const rowData = v;
        const normalList = [];
        const giftList = [];
        const backList = [];
        if (rowData.lineType === '10') {
          rowData.promotionPolicyName = rowData.promotionPolicyInfo || rowData.promotionPolicyName;
          delete rowData.options;
          delete rowData.promotionPolicyInfo;
          // eslint-disable-next-line radix
          rowData.productNum = parseInt(rowData.productNum);
          normalList.push({
            promotionPolicyCode: rowData.promotionPolicyCode,
            promotionPolicyName: rowData.promotionPolicyName,
            promotionPolicyId: rowData.promotionPolicyId,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        } else if (rowData.lineType === '30') {
          backList.push({
            feeCode: rowData.feeCode,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        } else {
          giftList.push({
            promotionPolicyCode: rowData.promotionPolicyCode,
            promotionPolicyName: rowData.promotionPolicyName,
            promotionPolicyId: rowData.promotionPolicyId,
            productCode: rowData.productCode,
            productName: rowData.productName,
            productNum: rowData.productNum,
            imgUrl: rowData.imgUrl,
            saleUnitName: rowData.saleUnitName,
          });
        }
        if (rowData.giftList && rowData.giftList.length > 0) {
          rowData.giftList.forEach((a) => {
            giftList.push({
              promotionPolicyCode: rowData.promotionPolicyCode,
              promotionPolicyName: rowData.promotionPolicyName,
              promotionPolicyId: rowData.promotionPolicyId,
              productCode: a.productCode,
              productName: a.productName,
              productNum: a.productNum,
              imgUrl: a.imgUrl,
              saleUnitName: a.saleUnitName,
            });
          });
        }
        delete rowData.giftList;
        items.push({
          backList,
          giftList,
          normalList,
        });
      });
      const params = {
        ...this.getFormData(),
        fromType: 4,
        groupItemVos: items,
      };
      delete params.items;
      let path = '';
      switch (this.formConfig.orderType) {
        case '1':
          path = this.path.order.add;
          break;
        case '6':
          path = this.path.goods.add;
          break;
        case '7':
          path = this.path.free.add;
          break;
        default:
          break;
      }
      if (btn === 2) {
        path = '/dms/m/order/temporary/add';
        if (this.formConfig.id) {
          path = '/dms/m/order/edit';
        }
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
