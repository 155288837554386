<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-17 15:32:21
-->
<script>
import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      params: { },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('dms_order_manage_table');
  },
  methods: {
    clickVisible({ val, row }) {
      if (row.temporaryFlag === 'N') {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.id = row.id;
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增标准订单';
        this.formConfig.selection = [];
        this.formConfig.orderType = '1';
        this.openFull();
      } else if (val.code === 'goods_order') {
        this.modalConfig.title = '新增货补订单';
        this.formConfig.selection = [];
        this.formConfig.orderType = '6';
        this.openFull();
      } else if (val.code === 'free_order') {
        this.modalConfig.title = '新增免费订单';
        this.formConfig.selection = [];
        this.formConfig.orderType = '7';
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.id = row.id;
        this.openFull();
      }
      return true;
    },
  },
};
</script>
