<!--
 * @Author: yzr
 * @Date: 2020-11-26 16:28:59
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-29 16:34:14
-->
<template>
  <div>
    <el-row :gutter="20"
            v-if="!disabled">
      <el-col :span="2"
              v-if="orderType !== '6'">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="openModal">添加商品</el-button>
      </el-col>
      <!-- <el-col :span="2" v-if="orderType !== '7'">
        <el-button type="text"
                   icon="el-icon-edit" @click="openAdd">添加货补商品</el-button>
      </el-col> -->
      <el-col :span="2">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="clearTableData">清空</el-button>
      </el-col>
    </el-row>
    <el-scrollbar ref="myScrollbar"
                  style="height:400px"
                  wrap-class="content-scrollbar"
                  :native="false"
                  :noresize="false"
                  tag="div">
      <div v-for="(item,k) in tableData"
      class='table-box'
           :ref="item.key || 'productList'"
           :key="k">
        <div>{{'政策编码:' + item.key}}</div>
        <el-table :data="item.data"
                  :row-key="(row)=>{
                  return row.id + row.productCode + row.lineType
                }"
                  :show-header="k===0"
                  border
                  size="mini"
                  class="table"
                  default-expand-all
                  :tree-props="{children: 'giftList'}">
          <el-table-column v-bind="item.props"
                           :label="item.title"
                           v-for="(item,index) in tableList"
                           :prop="item.field"
                           :key="index">
            <template slot-scope="scope">
              <el-popover v-if="!item.props.type"
                          placement="top-start"
                          trigger="hover"
                          :disabled="!scope.row.isErr"
                          :content="scope.row.calSucessMsg">
                <span slot="reference">{{analysisField(scope,item)}}</span>
              </el-popover>
              <div v-else-if="item.props.type === 'input'">
                <el-input v-model="scope.row[item.field]"
                          clearable
                          v-if="!scope.row.hideControl && !disabled"
                          placeholder="请输入内容"></el-input>
                <span v-else>{{analysisField(scope,item)}}</span>
              </div>
              <div v-else-if="item.props.type === 'inputNumber'">
                <el-input-number v-model="scope.row[item.field]"></el-input-number>
              </div>
              <div v-else-if="item.props.type === 'select'">
                <el-select placeholder="请选择"
                           multiple
                           collapse-tags
                            :value="scope.row.promotionList||[]"
                           @change="(val)=>changeForm(val,scope,k)">
                  <el-option v-for="(child) in (scope.row.options || [])"
                             :key="child.promotionPolicyCode"
                             :label="child.promotionPolicyName || ''"
                             :value="child.promotionPolicyCode || ''">
                  </el-option>
                </el-select>
              </div>

            </template>
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           v-if="!disabled">
            <template slot-scope="scope">
              <i class="el-icon-delete-solid"
                 v-if="clickVisible(scope)"
                 @click="deleteProduct(scope)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-scrollbar>
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
    <replenishment :customerCode="customerCode"
                   :backList="value.tableData"
                   v-if="isReplenishment"
                   @closeAdd="closeAdd"
                   @submitAdd="submitAdd" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import replenishment from './replenishment.vue';

import settle from './settle';

export default {
  name: 'CheckProductGoods',
  components: {
    SelectConfig,
    replenishment,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        tableData: [],
        backList: [],
        giftList: [],
        normalList: [],
      }),
    },
    errMsg: String,
    errType: Boolean,
    disabled: Boolean,
    customerCode: String,
    orderType: String,
  },
  data() {
    return {
      tableData: [{ data: [] }],
      tableList: [],
      loading: true,
      isReplenishment: false,
      timer: null,
      handleIndex: 0,
      dictList: [],
    };
  },
  created() {
    const params = {
      function_code: 'dms_order_settle_table',
      functionCode: 'dms_order_settle_table',
    };
    request.post('/mdm/mdmtableconfig/query', params).then((res) => {
      const tableList = [];
      res.result.column.forEach((v) => {
        const rowData = v;
        rowData.props = this.mateFieldType(rowData.field, rowData.title);
        if (!rowData.props.hidden) {
          tableList.push(rowData);
        }
      });
      this.tableList = tableList;
      // console.log(this.value.tableData);
    });
    // this.getDictSelect();
  },
  methods: {
    // 隐藏按钮
    clickVisible(scope) {
      return scope.row.lineType !== '20';
    },
    // 解析字段
    analysisField(scope, item) {
      return scope.row[item.field];
    },
    // 匹配类型
    mateFieldType(field, title) {
      const con = document.createElement('canvas').getContext('2d');
      con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
      // 生成宽度查询函数
      const strlen = (str) => con.measureText(str).width;
      let minWidth = strlen(title) + 20 > 100 ? strlen(title) + 20 : 100;
      minWidth = Math.ceil(minWidth) + 10;
      let rowProps = {
        align: 'center',
        showOverflowTooltip: true,
        minWidth,
        // width: 50,
      };
      switch (field) {
        case 'productNum':
          rowProps = {
            ...rowProps,
            type: 'inputNumber',
            width: '200',
          };
          break;
        case 'promotionPolicyName':
          rowProps = {
            ...rowProps,
            type: 'select',
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        case 'promotionPolicyCode':
          rowProps = {
            ...rowProps,
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        default:
          break;
      }
      return rowProps;
    },
    // 清空
    clearTableData() {},
    // 打开弹窗
    openModal() {
      if (!this.customerCode) {
        this.$message('请选择客户');
        return false;
      }
      const normalList = [];
      this.value.tableData.forEach((v) => {
        if (v.lineType === '10') {
          normalList.push(v);
        }
      });
      let params = null;
      if (this.orderType === '7') {
        params = {
          functionCode: 'dms_free_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            customerCode: this.customerCode,
            conditionTypeCode: 'sale_price',
          },
        };
      } else {
        params = {
          functionCode: 'dms_check_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            cusCode: this.customerCode,
          },
        };
      }

      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      val.map((a) => {
        const row = a;
        row.lineType = '10';
        row.options = [
          {
            type: 'ratio',
            promotionPolicyCode: 'p1',
            promotionPolicyName: '折扣1',
            isAdd: true,
          },
          {
            type: 'gift',
            promotionPolicyCode: 'p2',
            promotionPolicyName: '买赠2',
            isAdd: true,
          },
          {
            type: 'ratio',
            promotionPolicyCode: 'p3',
            promotionPolicyName: '折扣3（不叠加）',
          },
          {
            type: 'amount',
            promotionPolicyCode: 'p4',
            promotionPolicyName: '满减4',
            isAdd: true,
          },
          {
            type: 'ratio',
            promotionPolicyCode: 'p5',
            promotionPolicyName: '折扣5',
            isAdd: true,
          },
          {
            type: 'gift',
            promotionPolicyCode: 'p6',
            promotionPolicyName: '买赠6',
            isAdd: true,
          },
        ];
        return row;
      });
      this.tableData[this.tableData.length - 1].data = val;
      settle.tableData = this.tableData;
    },
    changeForm(val, scope, index) {
      this.tableData = [];
      this.tableData = settle.handlePromotion(val, scope.row, index);
      this.$nextTick(() => {
        settle._self = this;
        const scrollTop = settle.getScrollTop();
        if (typeof scrollTop === 'number') {
          this.$refs.myScrollbar.wrap.scrollTop = scrollTop;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  margin-bottom: 12px;
}
/deep/.giftStyle {
  background: #f5f7fa94;
}

/deep/.isErr {
  // background:red;
  color: red;
}

/deep/.el-table th,
.el-table tr {
  background: #e8e8e8 !important;
}
/deep/.content-scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 100%;
}

</style>
