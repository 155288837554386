var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.orderType !== "6"
                ? _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.openModal },
                        },
                        [_vm._v("添加商品")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orderType !== "7"
                ? _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.openAdd },
                        },
                        [_vm._v("添加货补商品")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: { click: _vm.clearTableData },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.value.tableData,
                "row-key": function (row) {
                  return row.id + row.productCode + row.lineType
                },
                border: "",
                size: "mini",
                "header-row-class-name": "headerRowClassName",
                "default-expand-all": "",
                "tree-props": { children: "giftList" },
                "row-class-name": _vm.setClass,
              },
            },
            [
              _vm._l(_vm.tableList, function (item, index) {
                return _c(
                  "el-table-column",
                  _vm._b(
                    {
                      key: index,
                      attrs: { label: item.title, prop: item.field },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !item.props.type
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-start",
                                          trigger: "hover",
                                          disabled: !scope.row.isErr,
                                          content: scope.row.calSucessMsg,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.analysisField(scope, item)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : item.props.type === "input"
                                  ? _c(
                                      "div",
                                      [
                                        !scope.row.hideControl && !_vm.disabled
                                          ? _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请输入内容",
                                              },
                                              on: {
                                                input: function (val) {
                                                  return _vm.changeForm(
                                                    val,
                                                    scope
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row[item.field],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    item.field,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row[item.field]",
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analysisField(scope, item)
                                                )
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : item.props.type === "inputNumber"
                                  ? _c(
                                      "div",
                                      [
                                        (!scope.row.hideControl ||
                                          _vm.otherHidden(scope)) &&
                                        !_vm.disabled
                                          ? _c("el-input-number", {
                                              on: {
                                                change: function (val) {
                                                  return _vm.changeForm(
                                                    val,
                                                    scope
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row[item.field],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    item.field,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row[item.field]",
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analysisField(scope, item)
                                                )
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : item.props.type === "select"
                                  ? _c(
                                      "div",
                                      [
                                        !scope.row.hideControl && !_vm.disabled
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                  loading: _vm.loading,
                                                },
                                                on: {
                                                  change: function (val) {
                                                    return _vm.changeForm(
                                                      val,
                                                      scope
                                                    )
                                                  },
                                                  focus: function () {
                                                    return _vm.getPromotionOptions(
                                                      scope,
                                                      _vm.tableList[index]
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row[item.field],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      item.field,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row[item.field]",
                                                },
                                              },
                                              _vm._l(
                                                item.props.options ||
                                                  scope.row.options ||
                                                  [],
                                                function (child, i) {
                                                  return _c("el-option", {
                                                    key: i,
                                                    attrs: {
                                                      label: child.label || "",
                                                      value: child.value || "",
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analysisField(scope, item)
                                                )
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "el-table-column",
                    item.props,
                    false
                  )
                )
              }),
              !_vm.disabled
                ? _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.clickVisible(scope)
                                ? _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteProduct(scope)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1867705006
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _vm.isReplenishment
        ? _c("replenishment", {
            attrs: {
              customerCode: _vm.customerCode,
              backList: _vm.value.tableData,
            },
            on: { closeAdd: _vm.closeAdd, submitAdd: _vm.submitAdd },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }