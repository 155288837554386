const settle = {
  promotionList: [],
  tableData: [],
  checkCode: '',
  deleteCode: '',
  scrollCode: '',
  _self: null,
  // 数据深拷贝
  copyData(data) {
    return JSON.parse(JSON.stringify(data));
  },
  // 政策信息数据
  handlePromotion(list, val, index) {
    const data = this.copyData(val);
    // 判断当前操作是否是删除操作，并获取删除的code
    data.promotionList = data.promotionList || [];
    this.deleteCode = '';
    this.checkCode = '';
    this.scrollCode = '';

    if (list.length < data.promotionList.length) {
      this.deleteCode = this.handleCheckPromotion(list, data.promotionList);
    } else {
      this.checkCode = this.handleCheckPromotion(list, data.promotionList);
    }

    // 有值则说明是删除操作
    if (this.deleteCode) {
      data.promotionList = list;
      this.deletePromotion(data, index);
    } else {
      this.checkPromotion(data, index);
    }
    return this.tableData;
  },
  // 判断该政策中是否已有该商品
  handlePromorionProduct(index, data) {
    let isSet = false;
    this.tableData[index].data.map((a) => {
      const row = a;
      if (a.productCode === data.productCode) {
        isSet = true;
        row.productNum += 1;
      }
      return row;
    });
    if (!isSet) {
      this.tableData[index].data.push(data);
    }
  },
  // 获取促销信息
  getProdmotionInfo(checkCode, data) {
    const info = data.options.find((b) => b.promotionPolicyCode === checkCode);
    return info;
  },
  // 判断当前操作是选中政策还是取消
  handleCheckPromotion(list, promotionList = []) {
    let code = '';
    // 根据当前选择数量和缓存数量对比判断是否删除
    if (list.length < promotionList.length) {
      promotionList.forEach((a) => {
        let isCheck = true;
        list.forEach((b) => {
          if (a === b) {
            isCheck = false;
          }
        });
        if (isCheck) {
          code = a;
        }
      });
    } else {
      list.forEach((a) => {
        let isCheck = true;
        promotionList.forEach((b) => {
          if (a === b) {
            isCheck = false;
          }
        });
        if (isCheck) {
          code = a;
        }
      });
    }
    return code;
  },
  // 给所有该商品绑定活动
  setProductPromotion(list, productCode) {
    const tableData = this.tableData.map((a) => {
      const aRow = a;
      aRow.data.forEach((b) => {
        const bRow = b;
        if (bRow.productCode === productCode) {
          bRow.promotionList = list;
        }
        return bRow;
      });
      return aRow;
    });
    this.tableData = tableData;
  },
  // 删除政策的逻辑
  deletePromotion(data, index) {
    // 第一步，修改当前商品的政策状态
    const newData = data;
    // 获取政策信息
    const info = this.getProdmotionInfo(this.deleteCode, data);
    // 判断是否删除的是赠品活动
    if (info.type === 'gift') {
      this.scrollCode = 'productList';
      // 是赠品活动的，要将该数据放到本品列表中
      // 从促销政策往本品列表里面放
      const { length } = this.tableData;
      // 判断是否还有本品列表
      if (!this.tableData[length - 1].key) {
        this.tableData[length - 1].data.unshift(newData);
      } else {
        // 没有的话，新建一个本品列表放在前面
        this.tableData.push({
          data: [newData],
        });
      }
      // 删除当前位置的数据
      const productIndex = this.getProductIndex(index, data);
      this.tableData[index].data.splice(productIndex, 1);
      // 判断是否还有本品类型的数据，没有则删除table
      this.handleHasProduct(index);
    } else {
      // 替换当前数据
      const productIndex = this.getProductIndex(index, data);
      this.tableData[index].data[productIndex] = newData;
    }
  },
  // 选中政策的逻辑
  checkPromotion(data, index) {
    // 第一步，修改当前商品的政策状态
    const newData = data;
    // 获取政策信息
    const info = this.getProdmotionInfo(this.checkCode, data);

    // 判断当前选择的政策可叠加是否和之前的一致
    if (newData.isAdd !== info.isAdd) {
      newData.promotionList = [this.checkCode];
      // 赋值新政策叠加状态
      newData.isAdd = info.isAdd;

      // 判断是否选择的是买赠活动，买赠活动需要在新的模块展示
      if (info.type === 'gift') {
        this.scrollCode = this.checkCode;
        this.handleGift(data, index);
      } else if (info.type !== 'gift') {
        // 不是赠品活动的情况，需要把数据放在本品列表里面
        // 先判断当前商品位置
        if (this.tableData[index].key) {
          // 从促销政策往本品列表里面放
          const { length } = this.tableData;
          // 判断是否还有本品列表
          if (!this.tableData[length - 1].key) {
            this.tableData[length - 1].data.unshift(newData);
          } else {
            // 没有的话，新建一个本品列表放在前面
            this.tableData.push({
              data: [newData],
            });
          }
          // 删除当前位置的数据
          const productIndex = this.getProductIndex(index, data);
          this.tableData[index].data.splice(productIndex, 1);
          // 判断是否还有本品类型的数据，没有则删除table
          this.handleHasProduct(index);
        } else {
          // 替换当前数据
          const productIndex = this.getProductIndex(index, data);
          this.tableData[index].data[productIndex] = newData;
        }
      }
    } else {
      // 去掉同类的政策
      newData.promotionList = this.handleSamePromotion(info.type, newData);
      // 将当前政策添加进去
      newData.promotionList.push(this.checkCode);

      // 赋值新政策叠加状态
      newData.isAdd = info.isAdd;

      // 判断是否选择的是买赠活动，买赠活动需要在新的模块展示
      if (info.type === 'gift') {
        this.scrollCode = this.checkCode;
        this.handleGift(data, index);
      } else {
        // 替换当前数据
        const productIndex = this.getProductIndex(index, data);
        this.tableData[index].data[productIndex] = newData;
      }
    }
  },
  // 查找是否已选同类政策
  handleSamePromotion(type, data) {
    const list = [];
    data.promotionList.forEach((v) => {
      const info = this.getProdmotionInfo(v, data);
      if (info.type !== type) {
        list.push(v);
      }
    });
    return list;
  },
  // 买赠活动逻辑处理
  handleGift(data, index) {
    // 判断当前选择政策是否已选中，并找到已选择的政策位置
    let isPromotion = false;
    let promotionIndex = 0;

    this.promotionList.forEach((b, i) => {
      if (this.checkCode === b) {
        isPromotion = true;
        promotionIndex = i;
      }
    });

    let newIndex = index;

    if (!isPromotion) {
      // 未设置促销政策新建政策模块并缓存政策code
      const tableData = this.copyData(this.tableData);
      tableData.unshift({
        key: this.checkCode,
        data: [
          data,
        ],
      });
      newIndex += 1;
      this.promotionList.unshift(this.checkCode);
      this.tableData = tableData;
    } else {
      // 已建促销政策模块查找是否有相同商品
      this.tableData[promotionIndex].data.unshift(data);
    }
    // 删除当前位置的数据
    const productIndex = this.getProductIndex(newIndex, data);
    this.tableData[newIndex].data.splice(productIndex, 1);
    // 判断是否还有本品类型的数据，没有则删除table
    this.handleHasProduct(newIndex);
  },
  // 删除操作以后需要判断当前政策中是否还有本品
  handleHasProduct(promotionIndex) {
    // 判断当前政策中是否还有本品
    let hasProduct = false;
    // 判断当前操作table里面是否还有本品类型的数据
    this.tableData[promotionIndex].data.forEach((a) => {
      if (a.lineType === '10') {
        hasProduct = true;
      }
    });
    if (!hasProduct) {
      // 如果当前table不存在key，那么删除的table是本品列表，不需要找对应的政策目录
      if (this.tableData[promotionIndex].key) {
        this.promotionList.splice(promotionIndex, 1);
      }
      this.tableData.splice(promotionIndex, 1);
    }
  },
  // 获取商品位置
  getProductIndex(index, data) {
    let productIndex = 0;
    this.tableData[index].data.forEach((a, k) => {
      if (a.productCode === data.productCode) {
        productIndex = k;
      }
    });
    return productIndex;
  },
  // 获取当前选择的政策的scrollTop
  getScrollTop() {
    if (this.scrollCode) {
      return this._self.$refs[this.scrollCode][0].offsetTop;
    }
    return null;
  },
};

export default settle;
