<!--
 * @Author: yzr
 * @Date: 2020-11-26 16:28:59
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-29 16:34:14
-->
<template>
  <div>
    <el-row :gutter="20" v-if="!disabled">
      <el-col :span="2" v-if="orderType !== '6'">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="openModal">添加商品</el-button>
      </el-col>
      <el-col :span="2" v-if="orderType !== '7'">
        <el-button type="text"
                   icon="el-icon-edit" @click="openAdd">添加货补商品</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="clearTableData">清空</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="value.tableData"
                :row-key="(row)=>{
                  return row.id + row.productCode + row.lineType
                }"
                border
                size="mini"
                header-row-class-name="headerRowClassName"
                default-expand-all
                :tree-props="{children: 'giftList'}"
                :row-class-name="setClass"
                style="width: 100%">
        <!-- <el-table-column label="序号"
                         width="80">
          <template slot-scope="scope">
            <span>{{scope.$index + 1}}</span>
          </template>
        </el-table-column> -->
        <el-table-column v-bind="item.props"
                         :label="item.title"
                         v-for="(item,index) in tableList"
                         :prop="item.field"
                         :key="index">
          <template slot-scope="scope">
            <el-popover
              v-if="!item.props.type"
              placement="top-start"
              trigger="hover"
              :disabled="!scope.row.isErr"
              :content="scope.row.calSucessMsg">
              <span slot="reference">{{analysisField(scope,item)}}</span>
            </el-popover>
            <div v-else-if="item.props.type === 'input'">
              <el-input v-model="scope.row[item.field]"
                        clearable
                        v-if="!scope.row.hideControl && !disabled"
                        @input="(val)=>changeForm(val,scope)"
                        placeholder="请输入内容"></el-input>
              <span v-else>{{analysisField(scope,item)}}</span>
            </div>
             <div v-else-if="item.props.type === 'inputNumber'">
              <el-input-number v-model="scope.row[item.field]"
                        v-if="(!scope.row.hideControl || otherHidden(scope)) && !disabled"
                        @change="(val)=>changeForm(val,scope)"></el-input-number>
              <span v-else>{{analysisField(scope,item)}}</span>
            </div>
            <div v-else-if="item.props.type === 'select'">
              <el-select placeholder="请选择"
                        clearable
                         v-model="scope.row[item.field]"
                         v-if="!scope.row.hideControl && !disabled"
                         :loading="loading"
                         @change="(val)=>changeForm(val,scope)"
                         @focus="()=>getPromotionOptions(scope,tableList[index])">
                <el-option v-for="(child,i) in (item.props.options || scope.row.options || [])"
                           :key="i"
                           :label="child.label || ''"
                           :value="child.value || ''">
                </el-option>
              </el-select>
              <span v-else>{{analysisField(scope,item)}}</span>
            </div>

          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" v-if="!disabled">
          <template slot-scope="scope">
            <i class="el-icon-delete-solid"
                v-if="clickVisible(scope)"
               @click="deleteProduct(scope)"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <replenishment :customerCode="customerCode" :backList="value.tableData" v-if="isReplenishment" @closeAdd="closeAdd" @submitAdd="submitAdd"/>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import replenishment from './replenishment.vue';

export default {
  name: 'CheckProductGoods',
  components: {
    SelectConfig,
    replenishment,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        tableData: [],
        backList: [],
        giftList: [],
        normalList: [],
      }),
    },
    errMsg: String,
    errType: Boolean,
    disabled: Boolean,
    customerCode: String,
    orderType: String,
  },
  watch: {
    orderType(n) {
      console.log(n);
      const tableList = [];
      this.tableList.forEach((v) => {
        const rowData = v;
        rowData.props = this.mateFieldType(rowData.field, rowData.title);
        if (!rowData.props.hidden) {
          tableList.push(rowData);
        }
      });
      this.tableList = tableList;
    },
  },
  data() {
    return {
      // tableData: [],
      tableList: [],
      loading: true,
      isReplenishment: false,
      timer: null,
      handleIndex: 0,
      dictList: [],
    };
  },
  created() {
    const params = {
      function_code: 'dms_order_settle_table',
      functionCode: 'dms_order_settle_table',
    };
    request.post('/mdm/mdmtableconfig/query', params).then((res) => {
      const tableList = [];
      res.result.column.forEach((v) => {
        const rowData = v;
        rowData.props = this.mateFieldType(rowData.field, rowData.title);
        if (!rowData.props.hidden) {
          tableList.push(rowData);
        }
      });
      this.tableList = tableList;
      // console.log(this.value.tableData);
    });
    this.getDictSelect();
  },
  methods: {
    otherHidden(scope) {
      const field = scope.column.property;
      if (field === 'productNum' && scope.row.lineType !== '20') {
        return true;
      }
    },
    clickVisible(scope) {
      return scope.row.lineType !== '20';
    },
    // 解析字段
    analysisField(scope, item) {
      if (item.field === 'lineType') {
        let lineTypeName = '赠品';
        switch (scope.row.lineType) {
          case '10':
            lineTypeName = '本品';
            break;
          case '30':
            lineTypeName = '货补';
            break;
          default:
            lineTypeName = '赠品';
            break;
        }
        return lineTypeName;
      }
      if (item.field === 'price') {
        let { price } = scope.row;
        // console.log(scope.row.resultDataType);
        if (scope.row.resultDataType === 'price') {
          price = scope.row.resultData;
        }
        return price;
      }
      if (item.field === 'amount') {
        // console.log(scope.row.productNum, scope.row.price);
        const { row } = scope;
        let total = scope.row.amount || this.floatMul(scope.row.productNum || 0, scope.row.price || 0);
        row.amount = total;
        if (scope.row.lineType === '10' && scope.row.promotionPolicyCode) {
          if (scope.row.resultDataType === 'ratio') {
            total = this.floatMul(total || 0, scope.row.resultData || 0);
          }
          if (scope.row.resultDataType === 'price') {
            total = this.floatMul(scope.row.productNum || 0, scope.row.resultData || 0);
          }
          if (scope.row.resultDataType === 'amount') {
            total -= scope.row.resultData;
          }
        }
        return total;
      }
      if (item.field === 'saleUnit') {
        const dict = this.dictList.find((a) => a.dictCode === scope.row.saleUnit);
        return dict ? dict.dictValue : scope.row.saleUnit;
      }
      return scope.row[item.field];
    },
    // 获取数据字典
    getDictSelect() {
      request.post('/mdm/mdmdictdata/batchDictSelect', ['product_sale_unit']).then((res) => {
        if (res.success) {
          this.dictList = res.result.product_sale_unit;
        }
      });
    },
    // 获取商品index
    getProductIndex(scope) {
      let index = 0;
      const { row } = scope;
      this.value.tableData.forEach((v, k) => {
        if (v.productCode === row.productCode && v.lineType === row.lineType) {
          index = k;
        }
      });
      return index;
    },
    getPromotionDetail(scope, promotionPolicyCode, promotionPolicyId, index) {
      const orderProductInfos = [];
      const { row } = scope;
      this.value.tableData.forEach((v) => {
        if (v.promotionPolicyCode === promotionPolicyCode) {
          orderProductInfos.push({
            productBuyAmount: row.amount,
            productBuyNo: row.productNum,
            productCode: row.productCode,
            promotionPolicyId,
          });
        }
      });
      const params = {
        custCode: this.customerCode,
        orderProductInfos,
      };
      request
        .post('/dms/promotion/policy/loadPromotionPolicyConfigInfoForOrder', params)
        .then(async (res) => {
          if (res.success) {
            const tableList = JSON.parse(JSON.stringify(this.value.tableData));
            // const index = this.getProductIndex(scope);
            let giftList = [];
            tableList[index].resultDataType = '';
            tableList[index].resultData = '';
            if (res.result[row.productCode] && res.result[row.productCode][0]) {
              const promotion = res.result[row.productCode][0];
              const { resultDataType, resultData } = promotion.promotionPolicyInfoVo.calculateRuleResponse;
              if (promotion.condition.promotionType !== '0') {
                tableList[index].resultDataType = resultDataType;
                tableList[index].resultData = resultData;
              } else {
                const productCodes = [];
                // console.log(resultData);
                promotion.productsGift.forEach((v) => {
                  const rowData = v;
                  rowData.hideControl = true;
                  rowData.lineType = '20';
                  rowData.promotionPolicyName = row.promotionPolicyInfo;
                  rowData.promotionPolicyCode = row.promotionPolicyCode;
                  rowData.productNum = resultDataType === 'number' ? resultData : '0';
                  rowData.amount = resultDataType === 'amount' ? resultData : '-';
                  rowData.parentProductCode = row.productCode;
                  productCodes.push(rowData.productCode);
                  giftList.push(rowData);
                  return rowData;
                });
                giftList = await this.getPrice(productCodes, giftList);
                if (resultDataType === 'amount') {
                  giftList.map((v) => {
                    const rowData = v;
                    rowData.amount = resultDataType === 'amount' ? resultData : '-';
                    rowData.productNum = Math.floor(rowData.amount / rowData.price);
                    return rowData;
                  });
                }
              }

              // tableList[index].isErr = false;
            } else {
              // tableList[index].isErr = true;
            }
            if (row.lineType === '10') {
              // console.log(giftList, 'giftList');
              tableList[index].giftList = giftList;
            }

            this.value.tableData = JSON.parse(JSON.stringify(tableList));

            this.$emit('quantityOrder');
          }
        });
    },
    changeForm(val, scope) {
      // const index = scope.$index;
      const field = scope.column.property;
      const { row } = scope;
      const index = this.getProductIndex(scope);
      this.handleIndex = index;
      // this.tableData[index][field] = val;
      if (field === 'promotionPolicyName') {
        this.deleteGift(scope);
        if (val) {
          const {
            giftList = [], value, label, promotionPolicyId,
          } = scope.row.options.find((v) => v.value === val);
          // eslint-disable-next-line no-param-reassign
          row.promotionPolicyCode = value;
          row.promotionPolicyInfo = label;
          row.promotionPolicyId = promotionPolicyId;

          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getPromotionDetail(scope, value, promotionPolicyId, index);
          }, 300);
        } else {
          this.value.tableData[index].giftList = [];
          this.value.tableData[index].resultDataType = '';
          this.value.tableData[index].resultData = '';
          row.promotionPolicyCode = '';
          row.promotionPolicyInfo = '';
          row.promotionPolicyId = '';
          this.$emit('quantityOrder');
        }
      }
      if (field === 'productNum') {
        row.amount = this.floatMul(val || 0, row.price || 0);
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (row.lineType === '10' && row.promotionPolicyCode) {
            this.getPromotionDetail(scope, row.promotionPolicyCode, row.promotionPolicyId, index);
          } else {
            this.$emit('quantityOrder');
          }
        }, 300);
      }
    },
    // 两个浮点数求和
    accAdd(num1 = 0, num2 = 0) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 乘
    floatMul(arg1 = 0, arg2 = 0) {
      let m = 0;
      const s1 = arg1.toString();
      const s2 = arg2.toString();
      // eslint-disable-next-line no-empty
      try { m += s1.split('.')[1].length; } catch (e) {}
      // eslint-disable-next-line no-empty
      try { m += s2.split('.')[1].length; } catch (e) {}
      // eslint-disable-next-line no-mixed-operators
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / 10 ** m;
    },
    // 删除赠品
    deleteGift(scope) {
      // eslint-disable-next-line no-plusplus
      for (let i = this.value.tableData.length; i > 0; i--) {
        const data = this.value.tableData[i - 1];
        if (data.parentProductCode === scope.row.productCode) {
          this.value.tableData.splice(i - 1, 1);
        }
      }
    },

    deleteProduct(scope) {
      let index = 0;
      this.value.tableData.forEach((v, k) => {
        if (v.id === scope.row.id) {
          index = k;
        }
      });
      this.value.tableData.splice(index, 1);
      this.deleteGift(scope);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('quantityOrder');
      }, 300);
    },
    clearTableData() {
      this.value.tableData = [];
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('quantityOrder');
      }, 300);
    },
    // 匹配类型
    mateFieldType(field, title) {
      const con = document.createElement('canvas').getContext('2d');
      con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
      // 生成宽度查询函数
      const strlen = (str) => con.measureText(str).width;
      let minWidth = strlen(title) + 20 > 100 ? strlen(title) + 20 : 100;
      minWidth = Math.ceil(minWidth) + 10;
      let rowProps = {
        align: 'center',
        showOverflowTooltip: true,
        minWidth,
        // width: 50,
      };
      switch (field) {
        case 'productNum':
          rowProps = {
            ...rowProps,
            type: 'inputNumber',
            width: '200',
          };
          break;
        case 'promotionPolicyName':
          rowProps = {
            ...rowProps,
            type: 'select',
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        case 'promotionPolicyCode':
          rowProps = {
            ...rowProps,
            width: '200',
            hidden: this.orderType === '6' || this.orderType === '7',
          };
          break;
        default:
          break;
      }
      return rowProps;
    },
    // 打开弹窗
    openModal() {
      if (!this.customerCode) {
        this.$message('请选择客户');
        return false;
      }
      const normalList = [];
      this.value.tableData.forEach((v) => {
        if (v.lineType === '10') {
          normalList.push(v);
        }
      });
      let params = null;
      if (this.orderType === '7') {
        params = {
          functionCode: 'dms_free_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            customerCode: this.customerCode,
            conditionTypeCode: 'sale_price',
          },
        };
      } else {
        params = {
          functionCode: 'dms_check_product',
          data: normalList,
          idKey: 'productCode',
          paramData: {
            cusCode: this.customerCode,
          },
        };
      }

      this.$refs.selectConfig.openSelectModal(params);
    },
    async onGetSelect(val) {
      let normalList = val;
      const backList = [];
      const giftList = [];
      const productCodes = [];
      normalList.map((v) => {
        const rowData = v;
        // console.log(rowData);
        rowData.imgUrl = rowData.pictureList ? JSON.parse(rowData.pictureList)[0].urlAddress : '';
        rowData.lineType = '10';
        rowData.productNum = rowData.productNum || 1;
        productCodes.push(rowData.productCode);
        return rowData;
      });
      // console.log(normalList);

      normalList = await this.getPrice(productCodes, normalList);
      this.value.tableData.forEach((v) => {
        if (v.lineType === '30') {
          backList.push(v);
        } else if (v.lineType === '20') {
          giftList.push(v);
        }
      });
      this.value.tableData = normalList.concat(backList);
      // this.setGiftToNormal(giftList, this.value.tableData);
      // this.$emit('setProduct', val);
      this.$emit('quantityOrder');
    },

    // 获取价格
    async getPrice(productCodes, normalList) {
      const params = {
        conditionTypeCodeList: ['sale_price'],
        customerCode: this.customerCode,
        productCodeList: productCodes,
      };
      const list = normalList;
      await request.post('/mdm/mdmPriceSettingController/search', params).then((res) => {
        if (res.success) {
          if (res.result) {
            for (const i in res.result) {
              if (res.result[i]) {
                let salePrice = 0;
                if (res.result[i].sale_price) {
                  salePrice = res.result[i].sale_price ? res.result[i].sale_price.price : 0;
                }
                list.map((v) => {
                  const rowData = v;
                  if (rowData.productCode === i) {
                    rowData.price = salePrice || 0;
                    rowData.amount = salePrice || 0;
                  }
                  return rowData;
                });
              }
            }
          }
        }
      });
      // console.log(list);
      return list;
    },
    // 获取促销政策
    getPromotionOptions(scope, column) {
      if (!scope.row.options || scope.row.options.length === 0) {
        this.loading = true;
        const params = {
          custCode: this.customerCode,
          productCode: scope.row.productCode,
        };
        request
          .post('/dms/promotion/policy/loadPromotionPolicyConfigInfoByProductCode', params)
          .then((res) => {
            this.loading = false;
            if (res.success) {
              const list = [];
              const tableList = JSON.parse(JSON.stringify(this.value.tableData));
              const index = this.getProductIndex(scope);
              res.result.forEach((v) => {
                list.push({
                  value: v.promotionPolicyInfoVo.promotionPolicyCode,
                  label: v.promotionPolicyInfoVo.promotionPolicyName,
                  giftList: v.productsGift || [],
                  promotionPolicyId: v.promotionPolicyInfoVo.id,
                });
              });
              tableList[index].options = list;
              this.value.tableData = JSON.parse(JSON.stringify(tableList));
            }
          });
      }
    },
    // 获取促销政策
    // getProductOptions(productCodes) {
    //   const params = {
    //     productCodes,
    //     custCode: this.customerCode,
    //   };
    //   request
    //     .post('/dms/promotion/policy/loadPromotionPolicyConfigInfoByProductCode', params)
    //     .then((res) => {
    //       if (res.success) {
    //         const list = [];
    //         this.value.normalList.forEach((v) => {
    //           const rowData = v;
    //           rowData.options = res.result[v.productCode].map((a) => ({
    //             value: a.promotionPolicyCode,
    //             label: a.promotionPolicyName,
    //           }));
    //           list.push(rowData);
    //         });
    //         this.tableData = list;
    //       }
    //     });
    // },
    // 关闭货补弹窗
    closeAdd() {
      this.isReplenishment = false;
    },
    openAdd() {
      if (!this.customerCode) {
        this.$message('请选择客户');
        return false;
      }
      this.isReplenishment = true;
    },
    // 货补弹窗传递过来的数据
    async submitAdd(val) {
      const backList = val;
      const tableData = [];
      const productCodes = [];
      backList.map((v) => {
        const rowData = v;
        rowData.lineType = '30';
        rowData.productCode = v.goodsProductCode;
        rowData.productName = v.goodsProductName;
        rowData.hideControl = true;
        productCodes.push(rowData.productCode);
        return rowData;
      });
      this.value.tableData.forEach((v) => {
        if (v.lineType !== '30') {
          tableData.push(v);
        }
      });
      this.value.tableData = tableData.concat(backList);
      this.isReplenishment = false;

      this.$emit('quantityOrder');
    },
    setClass(scope) {
      if (scope.row.lineType === '20') {
        return 'giftStyle';
      }
      if (scope.row.isErr) {
        return 'isErr';
      }
      return '';
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.giftStyle{
  background: #f5f7fa94;
}

/deep/.isErr{
  // background:red;
  color:red;
}

  /deep/.el-table th, .el-table tr{
    background: #e8e8e8 !important;
  }

</style>
